import { Link } from "react-router-dom"

const NoCookiesPlaceholder = ({ text, placeholderText, width, minHeight, maxHeight, settings, setSettings, showAcceptAllCookies }) => {
    const acceptAllCookies = () => {
        setSettings({
            ...settings,
            cookiesAccepted: true,
            acceptOnlyNecessaryCookies: false,
        })
    }

    return(
        <div className="container bg-light d-flex flex-column justify-content-evenly align-items-center" style={{ border: 0, width: width, maxHeight: maxHeight, minHeight: minHeight }}>
            <div className="fw-bold fs-4 text-center">
                { placeholderText }
            </div>
            { showAcceptAllCookies &&
                <>
                    <div className="col-4 d-flex justify-content-evenly flex-column">
                        <button className="btn btn-primary w-100 mb-2" onClick={ acceptAllCookies }>{ text.cookieEnableAll }</button>
                        <p className="mt-4">
                            { text.cookiePlaceholderExplanation } <Link to="/privacy">{ text.footerPrivacy }</Link>.
                        </p>
                    </div>
                    <div className="col-8">
                        
                    </div>
                </>
            }
        </div>
    )
}

export default NoCookiesPlaceholder
