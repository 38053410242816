import { IconBrandYoutube, IconBrandInstagram, IconBrandWhatsapp, IconMail, IconPhone, IconBrandFacebook } from "@tabler/icons-react"
import { Link } from "react-router-dom"
import { BASE_ASSETS_URL } from "../utils/constants"

const getFarmmagazineNameInLang = (language) => {
    switch(language) {
        case "it-IT":
            return "Drockerhof_Hofmappe_it-IT"
        default:
            return "Drockerhof_Hofmappe_de-DE"
    }
}

const Footer = ({ text, settings }) => {
    const drockerhofLogo = text.navLogo ? require(`../assets/${text.footerLogo}.png`) : null
    const southTyrolLogo = require("../assets/suedtirol-logo.png") || null
    const funesLogo = require("../assets/funes-logo.jpg") || null
    const guestPassLogo = require("../assets/guest-pass-logo.png") || null
    const slowFoodLogo = require("../assets/slow-food-logo.jpg") || null
    const furchettaLogo = require("../assets/furchetta-logo.jpg") || null
    const organicLogo = require("../assets/organic-logo.png") || null

    let guestPassUrl
    switch(settings.language) {
        case "it-IT":
            guestPassUrl = "https://www.suedtirol.info/it/it/information/suedtirol-guest-pass"
            break
        case "en-US":
            guestPassUrl = "https://www.suedtirol.info/en/en/information/suedtirol-guest-pass"
            break
        default:
            guestPassUrl = "https://www.suedtirol.info/de/de/information/suedtirol-guest-pass"
            break
    }
    
    return (
        <footer className="container mt-5">
            <div className="row mb-3">
                <div className="col">
                    <img src={ drockerhofLogo } alt="Drockerhof Logo" width="150" height="140" className="d-inline-block align-text-top" />
                    <p className="fw-lighter text-left mb-1">{ text.footerAddress1 }</p>
                    <p className="fw-lighter text-left mb-1">{ text.footerAddress2 }</p>
                    <p className="fw-lighter text-left mb-1">{ text.footerAddress3 }</p>
                </div>
                <div className="col">
                    <ul className="list-group list-group-flush mb-3">
                        <li className="list-group-item text-left"><a className="link-underline link-underline-opacity-0" href={ `${BASE_ASSETS_URL}documents/${getFarmmagazineNameInLang(settings.language)}.pdf` } target="_blank" rel="noreferrer">{ text.footerFarmmagazine }</a></li>
                        <li className="list-group-item text-left"><Link className="link-underline link-underline-opacity-0" to="arrival">{ text.footerArrival }</Link></li>
                        <li className="list-group-item text-left"><Link className="link-underline link-underline-opacity-0" to="prices">{ text.footerPrices }</Link></li>
                    </ul>
                    <div className="row">
                        <div className="col text-center">
                            <a href="https://www.youtube.com/@Drockerhof" target="_blank" rel="noreferrer">
                                <IconBrandYoutube />
                            </a>
                        </div>
                        <div className="col text-center">
                            <a href="https://www.facebook.com/profile.php?id=61558862037339" target="_blank" rel="noreferrer">
                                <IconBrandFacebook />
                            </a>
                        </div>
                        <div className="col text-center">
                            <a href="https://www.instagram.com/drockerhof/" target="_blank" rel="noreferrer">
                                <IconBrandInstagram />
                            </a>
                        </div>
                        <div className="col text-center">
                            <a href={ `https://wa.me/+393420302036?text=${ text.whatsappMessage }` } target="_blank" rel="noreferrer">
                                <IconBrandWhatsapp />
                            </a>
                        </div>
                        <div className="col text-center">
                            <a href="mailto:info@drockerhof.it" target="_blank" rel="noreferrer">
                                <IconMail />
                            </a>
                        </div>
                        <div className="col text-center">
                            <a href="tel:+390472840030" target="_blank" rel="noreferrer">
                                <IconPhone />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row mb-3">
                <a className="col drocker-footer-logo" href="https://www.suedtirol.info/" target="_blank" rel="noreferrer">
                    <img src={ southTyrolLogo } alt="Suedtirol logo" style={{ maxHeight: "100px", maxWidth: "200px" }} />
                </a>
                <a className="col drocker-footer-logo" href="https://www.villnoess.com/" target="_blank" rel="noreferrer">
                    <img src={ funesLogo } alt="Funes Logo" style={{ maxHeight: "100px", maxWidth: "200px" }} />
                </a>
                <a className="col drocker-footer-logo" href={ guestPassUrl } target="_blank" rel="noreferrer">
                    <img src={ guestPassLogo } alt="DolomitiCard Mobil Logo" style={{ maxHeight: "100px", maxWidth: "200px" }} />
                </a>
                <a className="col drocker-footer-logo" href="https://www.fondazioneslowfood.com/" target="_blank" rel="noreferrer">
                    <img src={ slowFoodLogo } alt="Slow Food Logo" style={{ maxHeight: "100px", maxWidth: "200px" }} />
                </a>
                <a className="col drocker-footer-logo" href="http://www.furchetta.it/de/slow-food-travel-villnoess.html" target="_blank" rel="noreferrer">
                    <img src={ furchettaLogo } alt="Furchetta Logo" style={{ maxHeight: "100px", maxWidth: "200px" }} />
                </a>
                <a className="col drocker-footer-logo" href="https://www.abcert.it/" target="_blank" rel="noreferrer">
                    <img src={ organicLogo } alt="Organic / Bio Logo" style={{ maxHeight: "100px", maxWidth: "200px" }} />
                </a>
            </div>
            <div className="row mb-3">
                <p className="fw-lighter text-center">© Drockerhof - <Link to="privacy">{ text.footerPrivacy }</Link> - <Link to="legalinfo">{ text.footerLegalNotes }</Link> - { text.footerFiscalCode } MSSGTH70R31B160H</p>
                <p className="fw-lighter text-center">CIR: 021033-00000568 - CIN: IT021033B5WEAQG6Y8</p>
            </div>
        </footer>
    )
}

export default Footer
