const BlogPost = ({ title, subtitle, body, images }) => {
    const replacePlaceholders = (text, images) => {
        let replacedText = text ? text : ''

        if ((images !== undefined) && (images.length > 0))
            images.forEach((image, index) => {
                // Replace %image{index + 1}% with actual image component
                const altText = image.alt ? image.alt : `Image ${ index + 1 }`
                replacedText = replacedText.replace(new RegExp(`%image${index + 1}%`, 'g'), `<img class="drocker-blog-image drocker-blog-image-${ image.size }" src="${ image.url }" alt="${ altText }" />`)
            })

        return <p dangerouslySetInnerHTML={{ __html: replacedText }} />
    }

    return (
        <div className="card mb-3">
            <div className="card-body">
                { title && <h5 className="card-title">{ title }</h5> }
                { subtitle && <h6 className="card-subtitle mb-2 text-body-secondary">{ subtitle }</h6> }
                <div className="card-text text-center">{ replacePlaceholders(body, images) }</div>
            </div>
        </div>
    )
}

export default BlogPost
