import { BASE_ASSETS_URL } from "../utils/constants"

const ServicesPage = ({ settings, text }) => {
    let guestPassUrl
    switch(settings.language) {
        case "it-IT":
            guestPassUrl = "https://www.suedtirol.info/it/it/information/suedtirol-guest-pass"
            break
        case "en-US":
            guestPassUrl = "https://www.suedtirol.info/en/en/information/suedtirol-guest-pass"
            break
        default:
            guestPassUrl = "https://www.suedtirol.info/de/de/information/suedtirol-guest-pass"
            break
    }
    const guestPassLogo = require("../assets/guest-pass-logo.png") || null

    return (
        <div className="drocker-body">
            <div className="drocker-responsive-image" style={{ backgroundImage: `url(${BASE_ASSETS_URL}images/garden_1.jpg)` }}></div>
            <div className="container">
                <h1>{ text.servicesTitle }</h1>
                <p>{ text.servicesBody }</p>
                <ul className="list-group">
                    <li className="list-group-item">{ text.servicesDolomitiMobilCard } <a className="link-offset-2 link-underline link-underline-opacity-0" target="_blank" href={ guestPassUrl } rel="noreferrer">Guest Pass</a>.<sup>1</sup>
                        <br />
                        <a className="link-offset-2 link-underline link-underline-opacity-0" target="_blank" href={ guestPassUrl } rel="noreferrer">
                            <img src={ guestPassLogo } alt="Guest Pass Logo" height="80" className="d-inline-block align-text-top" />
                        </a>
                    </li>
                    <li className="list-group-item">{ text.servicesWorkWithFamilyMessner }<br /><span className="fw-lighter">{ text.servicesWorkWithFamilyMessnerDetails }</span></li>
                    <li className="list-group-item">{ text.servicesVegetableGarden }</li>
                    <li className="list-group-item">{ text.servicesBooks }</li>
                    <li className="list-group-item">{ text.servicesBoardgames }</li>
                    <li className="list-group-item">{ text.servicesEggs }</li>
                    <li className="list-group-item">{ text.servicesGrill }</li>
                    <li className="list-group-item">{ text.servicesDeckchairs }</li>
                    <li className="list-group-item">{ text.servicesWashingmashines }</li>
                    <li className="list-group-item">{ text.servicesTowels }</li>
                </ul>
                <br />
                <p dangerouslySetInnerHTML={{ __html: text.servicesFootnotes }} />
            </div>
        </div>
    )
}

export default ServicesPage
